import React from 'react';
import { Route } from 'react-router-dom';
import Home from '../home';
import About from '../about';
import 'bootstrap/dist/css/bootstrap.min.css';
import Topbar from '../navigation/Navbar';
import Foot from '../navigation/Foot';

const App = () =>
    <div>
        <Topbar />
        <main>
            <Route exact path="/" component={Home} />
            <Route exact path="/about-us" component={About} />
        </main>
        <Foot />
    </div>
;

export default App;
