import {Navbar, Container, Nav} from 'react-bootstrap';
import React, {Component} from 'react';

class Topbar extends Component {
    render() {
        return (

            <Navbar className='navBarContainer fixed-top'>
                <Container>
                    <Navbar.Brand href="/"><img
                        src="/images/Designed.png"
                        height="70"
                        className="d-inline-block align-top"
                        alt="PTMD collection"
                    /></Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                        <Nav >
                            <Nav.Link href="/"><i className="fas fa-home"></i></Nav.Link>
                            <Nav.Link href="https://ptmd.nl/store-locator"><i className="fas fa-store"></i></Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        );
    }
}

export default Topbar;
