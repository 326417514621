import React from 'react';
import { Col, Row, Container, Button } from 'react-bootstrap';
import { getTextTop, getTextMiddle, getTextBottom } from './../../text/homepage';

const Home = () =>
    <div>
        <header className='masthead'>
        </header>
        <section className='intro-section'>
            <Container>
                <Row>
                    <Col md={8} className='text-col'>
                        {getTextTop()}
                        <br />
                        {getTextMiddle()}
                        <br />
                        {getTextBottom()}
                    </Col>
                    <Col md={4} className='button-col text-center'>
                        <div className='button-container'>
                            <Button variant='dark' href='https://ptmd.nl/store-locator'>Encuentra la tienda más cercana</Button>
                            <Button variant='dark' href='https://b2b.ptmd.nl/shop/login'>Acceso para profesionales</Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        <div className='img-section'></div>
    </div>
;

export default Home;
