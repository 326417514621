import {Container} from 'react-bootstrap';
import React, {Component} from 'react';

class Foot extends Component {
    render() {
        return (
            <section className='footer-section'>
                <Container className='footer'>
                    <span>2019 - PTMD Collection ®</span>
                </Container>
            </section>
        );
    }
}

export default Foot;
